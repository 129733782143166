<template>
  <div
    class="box"
    :style="{ top: node.top + 'px', left: node.left + 'px' }"
    @mousedown="handleDragstart($event, node)"
  >
    <FormOutlined class="icon-edit" @click="handleEdit" />
    <DeleteOutlined class="icon-delete" @click="handleDelete" />
    <h6 class="title no-wrap">{{ title }}</h6>
    <div class="list" v-for="(item, index) in value" :key="index">
      <div class="no-wrap">
        <div
        :title="item[attr.value]"
          ><input
            type="checkbox"
            :checked="item[attr.check]"
            @change="handChangeCheck(item)"
          />{{ item[attr.value] }}</div
        >
      </div>
      <div>
        <PlusCircleOutlined
          class="icon-add"
          :class="{ active: item[attr.check] }"
          @click="handleAddChild(index)"
        />
      </div>
    </div>
    <h6 class="tips title no-wrap" :title="tips" v-show="tips">备：{{ tips }}</h6>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
  DeleteOutlined,
  PlusCircleOutlined,
  FormOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  components: { DeleteOutlined, PlusCircleOutlined, FormOutlined },
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    node: Object,
    title: {
      type: String,
      default: '场景名'
    },
    tips: {
      type: String,
      default: ''
    },
    attr: {
      type: Object,
      default: () => {
        return {
          id: 'id',
          value: 'value',
          check: 'check'
        }
      }
    }
  },
  methods: {
    handleDelete() {
      this.$emit('handleDelete')
    },
    handChangeCheck(item) {
      item.check = !item.check
      this.$emit('changeCheck', item, this.node)
    },
    handleAddChild(index) {
      this.$emit('handleClick', index)
    },
    handleEdit() {
      this.$emit('handleEdit', this.node)
    },
    // 拖拽开始
    handleDragstart(e, node) {
      const el = document.getElementById(node.uid)
      const left = Number.parseFloat(el.style.left)
      const top = Number.parseFloat(el.style.top)
      const x = e.clientX
      const y = e.clientY
      document.onmousemove = ev => {
        const moveX = ev.clientX - x
        const moveY = ev.clientY - y
        // el.style.top = top + moveY + 'px'
        // el.style.left = left + moveX + 'px'
        node.top = top + moveY
        node.left = left + moveX
        this.$emit('changeLine', node.uid, node.left, node.top)
      }
      document.onmouseup = function () {
        document.onmousemove = null
        document.onmouseup = null
      }
      // node.top = e
    }
  }
})
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  width: 200px;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 1;
  cursor: all-scroll;
  overflow: hidden;
  user-select: none;
  &:hover {
    .icon-delete,
    .icon-edit {
      display: block;
    }
  }
  .icon-edit {
    display: none;
    position: absolute;
    top: 4px;
    right: 25px;
    cursor: pointer;
    color: royalblue;
  }
  .icon-delete {
    display: none;
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    color: red;
  }
  & > h6,
  & > div {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  & > div {
    font-size: 12px;
    input {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  & > div:last-child {
    border-bottom: none;
  }
  & > h6 {
    background-color: #eee;
  }
}
.list {
  display: flex;
  justify-content: space-between;
}
.icon-add {
  cursor: pointer;
  display: none;
  &:hover {
    color: royalblue;
  }
  &.active {
    display: block;
  }
}
.no-wrap {
  width: 100%;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list{
  div:nth-child(1){
    flex: 1;
  }
  div:nth-child(2){
    flex: 0 0 12px;
  }
}
.tips{
  // position: absolute;
}
</style>
