<template>
  <div ref="box" id="box">
    <left-mune>
      <template v-slot:canvastop>
        <div class="canvastop-box">
          <div></div>
          <div>
            <a-button type="primary" @click="handleAddProgress">保存</a-button>
            <a-button class="ml-15" type="primary" @click="handleConsole"
              >输出参数</a-button
            >
          </div>
        </div>
      </template>
      <template v-slot:canvasleft>
        <div class="canvasleft">
          <div>
            <label class="label">场景：</label><span>{{ listObj.scene }}</span>
          </div>
          <div class="flex-top mt-20">
            <label class="label">流程：</label>
            <div class="prop-list">
              <a-button type="primary" size="small" @click="addProcess"
                >添加流程</a-button
              >
              <div
                v-for="(item, index) in listObj.flow_list"
                :key="item.id"
                class="itemBox mt-10"
              >
                <div
                  class="name"
                  @click="handleClickFlow(item, index)"
                  :style="{ color: flowIndex == index ? '#155FEF' : '' }"
                >
                  {{ item.flow_name }}
                </div>
                <FormOutlined
                  v-if="
                    JSON.parse(userInfo).id == item.creator_id ||
                      JSON.parse(userInfo).role_id == 11
                  "
                  class="icon-edit"
                  @click="handleEditFlow(item, index)"
                />
                <DeleteOutlined
                  v-if="
                    JSON.parse(userInfo).id == item.creator_id ||
                      JSON.parse(userInfo).role_id == 11
                  "
                  class="icon-delete"
                  @click="handleDeleteFlow(item.id)"
                />
              </div>
            </div>
          </div>
          <div class="flex-top mt-20 flex-column">
            <div class="flex flex-align-c">
              <label class="label">道具：</label>
              <a-input
                v-model:value="searchName"
                style="width: 120px"
                placeholder="请输入道具名"
                @change="handleSeachName"
              />
            </div>
            <div class="prop-list mt-20">
              <template v-for="item in listObj.prop_list" :key="item.id">
                <div
                  v-show="!item.isShow"
                  class="mb-10"
                  @click="handleClickProp(item)"
                >
                  {{ item.prop }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <div
        class="x6-box-big"
        @mousewheel="handleMouseWheel"
        @mousedown.capture="handleDragstart"
      >
        <div ref="x6boxRef" id="x6-box" :style="setStyle()">
          <svg class="svg" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <!-- <line
                v-for="list in item.children"
                :key="list.uid"
                :x1="item.x"
                :y1="item.y"
                :x2="list.x"
                :y2="list.y"
                style="stroke: rgb(0, 0, 0); stroke-width: 1"
              /> -->
            <path
              v-for="item in lineArr"
              :key="item.uid"
              :d="
                createCPath(
                  item.x1 + item.offsetX,
                  item.y1 + item.offsetY,
                  item.x2,
                  item.y2 + 35 + 35 / 2
                )
              "
              :style="{
                stroke: item.color,
                fill: 'none',
                position: 'absolute'
              }"
            ></path>
          </svg>
          <list
            :id="list.uid"
            v-for="list in controlArr"
            :key="list.uid"
            :node="list"
            :tips="list.tips"
            :value="list.control_list"
            :title="list.prop"
            :attr="{ id: 'id', value: 'control', check: 'check' }"
            @contextmenu.prevent="handleOpenMenu($event, list)"
            @handleDelete="handleDelete(list)"
            @changeCheck="handChangeCheck"
            @changeLine="handChangeLine"
            @handleEdit="handleEdit"
            @handleClick="
              index => {
                handleClickChild(list, index)
              }
            "
          ></list>
        </div>
      </div>
    </left-mune>
  </div>
  <!-- 添加模型类型弹出层 -->
  <addDemoType
    :visible="isDemoTypeLayer"
    @handleCancel="handleCancelDemoType"
    @handelOK="handelDemoTypeOK"
  ></addDemoType>
  <!-- 添加事件弹出层 -->
  <addEventsLayer
    :list="listObj.prop_list"
    :visible="isEventLayer"
    @handleCancel="handleCancel"
    @handleChange="handleChangeEvents"
    @handelOK="handelEventsOK"
  ></addEventsLayer>

  <!-- 添加流程弹出层 -->
  <addProcessLayer
    ref="processRef"
    :visible="isProcessLayer"
    @handleCancel="handleProcessCancel"
    @handelOK="handelProcessOK"
  ></addProcessLayer>

  <!-- 鼠标右键菜单栏 -->
  <ul
    v-show="muneVisible"
    :style="{ left: muneLeft + 'px', top: muneTop + 'px' }"
    class="contextmenu"
  >
    <li @click="handleTips">修改备注</li>
  </ul>
  <!-- 修改备注的弹出层 -->
  <reviseTipsLayer
    v-model:visible="isReviseLayer"
    :list="nowClickItem"
    @handleOK="handleRevise"
    @handleCancel="handleCancelRevise"
  ></reviseTipsLayer>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from 'vue'
import leftMune from './components/leftMune'
import {
  bindFlowInfo,
  getSceneFlow,
  bindScene,
  getPropsFun,
  getSceneProp,
  addSceneFlow,
  editSceneFlow,
  deleteSceneFlow
} from '@/api/index'
import list from './components/list'
import addEventsLayer from './components/addEventsLayer'
import addDemoType from './components/addDemoType'
import addProcessLayer from './components/addProcessLayer'
import reviseTipsLayer from './components/reviseTipsLayer.vue'
import { ListType } from './hooks/config'
import { message } from 'ant-design-vue'
import { showConfirm, throttle } from '@/hooks/common-hooks'
import { DeleteOutlined, FormOutlined } from '@ant-design/icons-vue'
import { getInfo } from '@/api/cookie'
export default defineComponent({
  name: 'senceDes',
  props: ['id'],
  components: {
    leftMune,
    list,
    addEventsLayer,
    addDemoType,
    addProcessLayer,
    DeleteOutlined,
    FormOutlined,
    reviseTipsLayer
  },
  setup(props) {
    const userInfo = getInfo()
    const processRef = ref()
    const isEventLayer = ref(false)
    const isProcessLayer = ref(false)
    const isDemoTypeLayer = ref(false)
    const firstItem = ref(null)
    const searchName = ref('')
    const Scene = reactive({
      flowIndex: 0,
      listObj: {},
      controlObj: [], // 操作数据
      controlArr: [], // 渲染节点
      lineArr: [], // 曲线集合
      isPost: true, // 提交数据时的验证
      postArr: [], // 组装为后台需要的数据
      scale: 1,
      clientX: 0,
      clientY: 0
    })
    let currentData = null // 当权操作得节点数据
    let currentIndex = 0
    // 生成唯一的id值
    const uuid2 = (len, radix) => {
      const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
        ''
      )
      const uuid = []
      let i = 0
      radix = radix || chars.length
      if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
      } else {
        let r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | (Math.random() * 16)
            uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
          }
        }
      }
      return uuid.join('')
    }
    const setUid = () => {
      return uuid2(16, 16)
    }
    // 数据转换
    const changeData = (
      list,
      _arr = [],
      lineArr = [],
      pNode,
      level = 0,
      currentIndex
    ) => {
      for (let index = 0; index < list.length; index++) {
        _arr.push(list[index])
        if (level !== 0) {
          lineArr.push({
            source: pNode.uid,
            target: list[index].uid,
            x1: pNode.left,
            y1: pNode.top,
            x2: list[index].left,
            y2: list[index].top,
            offsetY: 35 + 35 / 2 + 35 * currentIndex,
            offsetX: 200,
            color: list[index].color
          })
        }
        list[index].control_list.forEach((x, i) => {
          if (Object.hasOwnProperty.call(x, 'children')) {
            changeData(x.children, _arr, lineArr, list[index], 1, i)
          }
        })
      }
    }
    const renderData = () => {
      Scene.controlArr = []
      Scene.lineArr = []
      changeData(Scene.controlObj, Scene.controlArr, Scene.lineArr)
    }
    // 删除数据
    const deleteData = (list, id) => {
      for (let index = 0; index < list.length; index++) {
        if (list[index].uid === id) {
          list.splice(index, 1)
        } else {
          list[index].control_list.forEach(x => {
            if (Object.hasOwnProperty.call(x, 'children')) {
              deleteData(x.children, id)
            }
          })
        }
      }
    }
    // 根据任务流程获取流程
    const getViewJson = obj => {
      Scene.lineArr = obj.view_json
        ? JSON.parse(JSON.parse(obj.view_json).view_line_json)
        : []
      Scene.controlObj = obj.view_json
        ? JSON.parse(JSON.parse(obj.view_json).view_json)
        : []
      renderData()
    }
    // 获取场景信息
    const getSceneInfo = () => {
      bindScene({
        scene_id: parseInt(props.id)
      }).then(res => {
        Scene.listObj.scene = res.data.scene
      })
    }
    // 根据场景获取道具
    const fetchGetProp = () => {
      getSceneProp({
        scene_id: props.id
      }).then(res => {
        Scene.listObj.prop_list = res.data
      })
    }
    const fetchBindFlowInfo = id => {
      bindFlowInfo({
        flow_id: id
      }).then(res => {
        processRef.value.init = res.data.untiy_json
          ? JSON.parse(res.data.untiy_json)
          : {}
        if (Scene.listObj.flow_list.length > 0) {
          getViewJson(res.data)
        }
      })
    }
    const fetchGetSceneFlow = () => {
      getSceneFlow({
        scene_id: parseInt(props.id)
      }).then(res => {
        Scene.listObj.flow_list = res.data
        if (!res.data.length) return false
        fetchBindFlowInfo(Scene.listObj.flow_list[Scene.flowIndex].id)
      })
    }
    // 动态获取
    const handleClickFlow = (obj, index) => {
      Scene.flowIndex = index
      fetchBindFlowInfo(obj.id)
    }
    // 取消模型类型弹出层
    const handleCancelDemoType = visible => {
      isDemoTypeLayer.value = visible
    }
    // 添加回调
    const addDemoCallback = (redioValue, textareaValue) => {
      getPropsFun({
        prop_id: firstItem.value.id
      }).then(res => {
        const _heightArr = Scene.controlObj.map(
          item => (item.control_list.length + 1) * 35
        )
        const _height =
          _heightArr.length === 0
            ? 0
            : _heightArr.reduce((total, e) => total + e)
        res.data.control_list.forEach(item => (item.mode = redioValue))
        Scene.controlObj.push({
          ...res.data,
          uid: setUid(),
          left: 10,
          top: _height,
          tips: textareaValue
        })
        // console.log(Scene.controlObj, '初始化页面数据')
        renderData()
      })
    }
    const handleClickProp = item => {
      if (
        Scene.listObj.flow_list.length === 0 ||
        !Scene.listObj.flow_list[Scene.flowIndex].id
      ) {
        message.error('请先添加流程')
        return false
      }
      firstItem.value = item
      // 选择模型类型
      handleCancelDemoType(true)
    }
    // 删除连线
    const deleteLine = uid => {
      Scene.lineArr = Scene.lineArr.filter(
        item =>
          item.source.split('-').includes(uid) &&
          item.target.split('-').includes(uid)
      )
    }
    // 删除道具
    const handleDelete = list => {
      deleteData(Scene.controlObj, list.uid)
      renderData()
    }
    // 选中事件
    const handChangeCheck = (item, node) => {
      if (!item.check) {
        delete item.children
        renderData()
      }
      // console.log(item, node, '输出1')
    }
    // 取消事件弹出层
    const handleCancel = visible => {
      isEventLayer.value = visible
    }
    const handleProcessCancel = visible => {
      isProcessLayer.value = visible
      processRef.value.title = '添加流程'
      processRef.value.flow_name = ''
      processRef.value.init = {}
    }
    // 事件弹出层选中道具得回调
    const handleChangeEvents = value => {
      // console.log(value)
    }
    // 点击添加子元素
    const handleClickChild = (data, index) => {
      currentData = data
      currentIndex = index
      isEventLayer.value = true
    }
    // 绘制曲线
    const createCPath = (x1, y1, x2, y2) => {
      let path = 'M' + x1 + ' ' + y1 + ' '
      const cx1 = x1
      const cy1 = (y1 + y2) / 2
      const cx2 = x2
      const cy2 = (y1 + y2) / 2
      const c = 'C' + cx1 + ' ' + cy1 + ',' + cx2 + ' ' + cy2 + ','
      path = path + c + x2 + ' ' + y2
      return path
    }
    // 弹出层确定事件
    const handelEventsOK = (value, radioValue, textareaValue) => {
      isEventLayer.value = false
      const _arr = Scene.listObj.prop_list.filter(item => item.id === value)
      getPropsFun({
        prop_id: _arr[0].id
      }).then(res => {
        if (
          !Object.hasOwnProperty.call(
            currentData.control_list[currentIndex],
            'children'
          )
        ) {
          currentData.control_list[currentIndex].children = []
        }
        let _top = currentData.top
        currentData.control_list.forEach(item => {
          if (item.children) {
            item.children.forEach(x => {
              _top += document.getElementById(x.uid).clientHeight + 10
            })
          }
        })
        const _uid = setUid()
        res.data.control_list.forEach(item => {
          item.mode = radioValue
        })
        currentData.control_list[currentIndex].children.push({
          ...res.data,
          uid: _uid,
          top: _top,
          left: currentData.left + 310,
          color: ListType.filter(item => item.id === radioValue)[0].color,
          tips: textareaValue
        })
        renderData()
      })
    }
    // 开启添加流程弹框
    const addProcess = () => {
      isProcessLayer.value = true
    }
    const handelProcessOK = (name, init) => {
      isProcessLayer.value = false
      if (processRef.value.title === '添加流程') {
        addSceneFlow({
          scene_id: props.id,
          flow_name: name,
          untiy_json: JSON.stringify(init)
        }).then(res => {
          fetchGetSceneFlow()
        })
      } else {
        editSceneFlow({
          scene_id: props.id,
          flow_id: processRef.value.flow_id,
          flow_name: name,
          untiy_json: JSON.stringify(init)
        }).then(() => {
          message.success('操作成功')
          fetchGetSceneFlow()
        })
      }
    }
    const handChangeLine = (uid, x, y) => {
      Scene.lineArr.forEach(item => {
        if (item.source === uid) {
          item.x1 = x
          item.y1 = y
        } else if (item.target === uid) {
          item.x2 = x
          item.y2 = y
        }
      })
    }

    // 验证数据
    const checkData = list => {
      for (let index = 0; index < list.length; index++) {
        list[index].control_list = list[index].control_list.filter(
          item => item.check
        )
        list[index].control_list.forEach(x => {
          if (Object.hasOwnProperty.call(x, 'children')) {
            checkData(x.children)
          }
        })
        if (list[index].control_list.length === 0) {
          Scene.isPost = false
        }
      }
    }
    // 添加流程
    const handleAddProgress = () => {
      Scene.isPost = true
      Scene.postArr = JSON.parse(JSON.stringify(Scene.controlObj))
      checkData(Scene.postArr)
      if (!Scene.isPost) {
        message.error('每个道具至少配置一个事件')
        return false
      }
      // console.log(JSON.stringify(Scene.postArr), '最后')
      editSceneFlow({
        scene_id: props.id,
        flow_id: Scene.listObj.flow_list[Scene.flowIndex].id,
        flow_name: Scene.listObj.flow_list[Scene.flowIndex].flow_name,
        view_data: {
          view_json: JSON.stringify(Scene.postArr),
          view_line_json: JSON.stringify(Scene.lineArr)
        }
      }).then(() => {
        message.success('操作成功')
        fetchBindFlowInfo(Scene.listObj.flow_list[Scene.flowIndex].id)
      })
    }
    const handleConsole = () => {
      const _aaa = JSON.parse(JSON.stringify(Scene.controlObj))
      checkData(_aaa)
      console.log('Scene.controlObj', Scene.controlObj)
      console.log('Scene.lineArr', Scene.lineArr)
      console.log('筛选后', _aaa)
    }
    // 点击编辑按钮重载当前盒子下面的事件数据
    const handleEdit = node => {
      console.log(node, 'node')
      getPropsFun({
        prop_id: node.id
      }).then(res => {
        node.control_list = node.control_list.concat(res.data.control_list)
        const obj = {}
        const result = []
        for (let index = 0; index < node.control_list.length; index++) {
          if (!obj[node.control_list[index].id]) {
            result.push(node.control_list[index])
            obj[node.control_list[index].id] = true
          }
        }
        node.control_list = result
      })
    }
    const setStyle = () => {
      return `transform-origin: 0px 0px 0px;transform:scale(${Scene.scale}) translate(${Scene.clientX}px, ${Scene.clientY}px)`
    }
    // 缩放视图
    const handleMouseWheel = e => {
      e.preventDefault()
      if (e.wheelDelta > 0) {
        // 向上滚动鼠标
        if (Scene.scale > 2) return false
        Scene.scale += 0.2
      } else {
        // 向下滚动鼠标
        if (Scene.scale < 0.5) return false
        Scene.scale -= 0.2
      }
      throttle.bounce(() => {
        setStyle()
      }, 800)
    }
    // 开始场景拖拽
    const handleDragstart = e => {
      const x = e.clientX
      const y = e.clientY
      const _x = Scene.clientX
      const _y = Scene.clientY
      document.onmousemove = ev => {
        const moveX = ev.clientX - x
        const moveY = ev.clientY - y
        Scene.clientX = _x + moveX
        Scene.clientY = _y + moveY
        throttle.bounce(() => {
          setStyle()
        }, 800)
      }
      document.onmouseup = function() {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
    // 编辑流程
    const handleEditFlow = (obj, index) => {
      console.log(obj)
      Scene.flowIndex = index
      fetchBindFlowInfo(Scene.listObj.flow_list[Scene.flowIndex].id)
      isProcessLayer.value = true
      processRef.value.title = '编辑流程'
      processRef.value.flow_id = obj.id
      processRef.value.flow_name = obj.flow_name
    }
    // 删除流程
    const handleDeleteFlow = id => {
      showConfirm({
        content: '此操作不可逆，确定删除该流程吗?',
        callBack: () => {
          deleteSceneFlow({
            flow_id: id
          }).then(() => {
            message.success('删除成功')
            fetchGetSceneFlow()
          })
        }
      })
    }

    const handelDemoTypeOK = (redioValue, textareaValue) => {
      addDemoCallback(redioValue, textareaValue)
      handleCancelDemoType(false)
      console.log(redioValue, textareaValue)
    }
    // 搜索道具
    const handleSeachName = () => {
      Scene.listObj.prop_list.filter(item => {
        if (item.prop.match(searchName.value)) {
          item.isShow = false
        } else {
          item.isShow = true
        }
      })
    }
    // 右键菜单栏
    const muneVisible = ref(false)
    const muneLeft = ref(0)
    const muneTop = ref(0)
    const nowClickItem = ref()
    const handleOpenMenu = (e, list) => {
      nowClickItem.value = list
      muneLeft.value = e.x + 10
      muneTop.value = e.y + 10
      muneVisible.value = true
    }

    const handleCloseMenu = () => {
      muneVisible.value = false
    }

    // 打开修改备注弹出层
    const isReviseLayer = ref(false)
    const handleReviseLayer = () => {
      isReviseLayer.value = !isReviseLayer.value
    }

    // 修改备注
    const handleTips = () => {
      handleReviseLayer()
    }
    const handleCancelRevise = visible => {
      isReviseLayer.value = visible
      nowClickItem.value = null
    }
    // 修改备注数据提交
    const handleRevise = value => {
      nowClickItem.value.tips = value
      handleReviseLayer()
      handleAddProgress()
    }

    watch(muneVisible, newValue => {
      if (newValue) {
        document.body.addEventListener('click', handleCloseMenu)
      } else {
        document.body.removeEventListener('click', handleCloseMenu)
      }
    })

    onMounted(() => {
      getSceneInfo()
      fetchGetProp()
      fetchGetSceneFlow()
    })
    return {
      ...toRefs(Scene),
      handleClickProp,
      handleDelete,
      handChangeCheck,
      isEventLayer,
      isProcessLayer,
      isDemoTypeLayer,
      handleCancel,
      handleProcessCancel,
      handleChangeEvents,
      handleClickChild,
      handelEventsOK,
      handelProcessOK,
      createCPath,
      handChangeLine,
      handleAddProgress,
      handleConsole,
      handleEdit,
      handleMouseWheel,
      setStyle,
      handleDragstart,
      addProcess,
      handleClickFlow,
      handleDeleteFlow,
      handleEditFlow,
      processRef,
      userInfo,
      handleCancelDemoType,
      handelDemoTypeOK,
      searchName,
      handleSeachName,
      muneVisible,
      muneLeft,
      muneTop,
      handleOpenMenu,
      handleTips,
      isReviseLayer,
      nowClickItem,
      handleRevise,
      handleCancelRevise
    }
  }
})
</script>

<style lang="scss" scoped>
#x6-box {
  position: relative;
  height: 100%;
  width: 3000px;
  overflow: hidden;
}
.canvastop-box {
  display: flex;
  justify-content: space-between;
  & > div > span {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: royalblue;
    }
  }
}
.canvasleft {
  & > div {
    display: flex;
    align-items: center;
  }
  .label {
    flex: 0 0 50px;
  }
  .flex-top {
    align-items: baseline;
  }
}
.prop-list {
  width: 100%;
  & > div {
    line-height: 1.5;
    cursor: pointer;
    user-select: none;
  }
  .name {
    width: 70%;
  }
  .itemBox {
    width: 100%;
    // background: red;
    position: relative;
    display: flex;
    &:hover {
      .icon-delete,
      .icon-edit {
        display: block;
      }
    }
    .icon-edit {
      // display: none;
      position: absolute;
      top: 4px;
      right: 20px;
      cursor: pointer;
      color: royalblue;
    }
    .icon-delete {
      // display: none;
      position: absolute;
      top: 4px;
      right: 0px;
      cursor: pointer;
      color: red;
    }
  }
}
.svg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
#box {
  /deep/ .canvas-right {
    overflow: hidden;
  }
}
.x6-box-big {
  height: 500%;
}
.contextmenu {
  position: absolute;
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  &:hover {
    color: #155fef;
  }
}
</style>
