<template>
  <a-modal
    width="60%"
    :visible="visible"
    title="选择道具类型"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handelOK"
  >
    <div class="box-con">
      <div class="list">
        <label class="label">类型：</label>
        <a-radio-group name="radioGroup" v-model:value="checkRadio">
          <a-radio :value="item.id" v-for="item in ListType" :key="item.id">{{
            item.name
          }}</a-radio>
        </a-radio-group>
      </div>
      <div class="list">
        <label class="label">操作提示：</label>
        <a-textarea v-model:value="textareaValue" placeholder="可输入提示内容" :rows="4" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, ref, watch } from 'vue'
import { ListType } from '../hooks/config'
export default defineComponent({
  props: {
    visible: Boolean
  },
  setup(props, ctx) {
    const checkRadio = ref(0)
    const textareaValue = ref('')
    const handleCancel = () => {
      ctx.emit('handleCancel', false)
    }
    // 验证数据
    const checkData = () => {
      if (checkRadio.value === 0) {
        message.error('请选择类型')
        return false
      }
      return true
    }
    const handelOK = () => {
      if (!checkData()) return false
      ctx.emit('handelOK', checkRadio.value, textareaValue.value)
    }
    watch(
      () => props.visible,
      newValue => {
        if (newValue) {
          checkRadio.value = 0
          textareaValue.value = ''
        }
      }
    )
    return {
      handleCancel,
      handelOK,
      ListType,
      checkRadio,
      textareaValue
    }
  }
})
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  margin-bottom: 20px;
  label {
    flex: 0 0 72px;
    text-align: right;
  }
}
.box-con {
  min-height: 400px;
}
</style>
