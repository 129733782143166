<template>
  <a-modal
    width="60%"
    :visible="visible"
    :title="title"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handelOK"
  >
    <div class="box-con">
      <div class="list">
        <label class="label">流程名：</label>
        <a-input
          v-model:value="flow_name"
          placeholder="请输入流程名称"
          :maxlength="50"
          autocomplete="off"
        ></a-input>
      </div>
      <div class="list">
        <label class="label">unity配置：</label>
        <vue-json-editors
          class="json-editors"
          :mode="'code'"
          v-model:value="init"
          lang="zh"
          :showBtns="false"
          @json-change="onJsonChange"
        ></vue-json-editors>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, ref, watch } from 'vue'
import vueJsonEditors from 'vue-json-editor'
export default defineComponent({
  props: {
    visible: Boolean
  },
  components: { vueJsonEditors },
  setup(props, ctx) {
    const title = ref('添加流程')
    const init = ref({})
    const flow_id = ref()
    const flow_name = ref('')
    const handleCancel = () => {
      ctx.emit('handleCancel', false)
    }
    const handelOK = () => {
      if (flow_name.value) {
        ctx.emit('handelOK', flow_name.value, init.value)
      } else {
        message.error('请先输入流程名')
      }
    }
    const onJsonChange = value => {
      console.log('value1:', value)
      init.value = value
    }
    return {
      handleCancel,
      handelOK,
      flow_id,
      flow_name,
      title,
      init,
      onJsonChange
    }
  }
})
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  margin-bottom: 20px;
  label {
    flex: 0 0 80px;
  }
}
.box-con {
  min-height: 200px;
  ::v-deep .jsoneditor-poweredBy {
    display: none !important;
  }
}
.json-editors {
  width: 100%;
  // background: red;
  ::v-deep .jsoneditor-vue {
    // background: red;
    min-height: 185px !important;
    div.jsoneditor-outer {
      min-height: 185px !important;
    }
  }
}
</style>
