// 道具列表
export const initObj = [
  {
    list: [
      {
        id: 1,
        value: '灭火器拿起事件'
      },
      {
        id: 2,
        value: '灭火器开保险事件'
      },
      {
        id: 3,
        value: '灭火器灭火事件'
      },
      {
        id: 4,
        value: '灭火器关保险事件'
      },
      {
        id: 5,
        value: '灭火器放下事件'
      }
    ],
    title: '灭火器'
  },
  {
    list: [
      {
        id: 1,
        value: '拿出打气筒'
      },
      {
        id: 2,
        value: '拿出气球'
      },
      {
        id: 3,
        value: '打气筒对准气球孔'
      },
      {
        id: 4,
        value: '开始挤压充气'
      },
      {
        id: 5,
        value: '充气完成打结'
      },
      {
        id: 6,
        value: '收好打气筒'
      }
    ],
    title: '气球打气'
  }
]

export const ListType = [
  {
    id: 1,
    name: '自动触发',
    color: '#4BDD13'
  },
  {
    id: 2,
    name: '手动触发',
    color: '#0E5AE1'
  },
  {
    id: 3,
    name: '限制触发',
    color: '#D70E0E'
  }
]
