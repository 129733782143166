<template>
  <div class="canvas-box">
    <div class="canvas-top">
      <slot name="canvastop"></slot>
    </div>
    <div class="canvas-content">
      <div class="canvas-left">
        <slot name="canvasleft"></slot>
      </div>
      <div class="canvas-right">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.canvas-box{
  width: 100%;
  height: calc(100vh - 125px);
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: .1);
}
.canvas-content{
  display: flex;
  height: 100%;
}
.canvas-left{
  position: relative;
  height: 100%;
  flex: 0 0 190px;
  padding: 15px;
  background-color:#F7F9FB;
  box-shadow: 1px 0 5px 0 rgba($color: #000000, $alpha: .1);
  z-index: 1;
  overflow-y: auto;
}
.canvas-top{
  position: relative;
  width: 100%;
  flex: 0 0 45px;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 0 rgba($color: #000000, $alpha: .1);
  z-index: 2;
}
.canvas-right{
  flex: 1;
  background-color: #fff;
}
</style>
