<template>
  <a-modal
    width="60%"
    :visible="visible"
    title="添加道具"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handelOK"
  >
    <div class="box-con">
      <div class="list">
        <label class="label">道具：</label
        ><a-select
          show-search
          v-model:value="selectValue"
          style="width: 100%"
          placeholder="请选择道具"
          :filter-option="filterOption"
          @change="handleChange"
        >
          <a-select-option
            v-for="item in list"
            :value="item.id"
            :key="item.id"
            :label="item.prop"
            >{{ item.prop }}</a-select-option
          >
        </a-select>
      </div>
      <div class="list">
        <label class="label">类型：</label>
        <a-radio-group name="radioGroup" v-model:value="checkRadio">
          <a-radio :value="item.id" v-for="item in ListType" :key="item.id">{{
            item.name
          }}</a-radio>
        </a-radio-group>
      </div>
      <div class="list">
        <label class="label">备注：</label>
        <a-textarea v-model:value="textareaValue" placeholder="可输入备注内容" :rows="4" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, ref, watch } from 'vue'
import { ListType } from '../hooks/config'
export default defineComponent({
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    visible: Boolean
  },
  setup(props, ctx) {
    const checkRadio = ref(0)
    const selectValue = ref('')
    const textareaValue = ref('')
    const handleCancel = () => {
      ctx.emit('handleCancel', false)
    }
    const handleChange = value => {
      // selectValue.value = value
      // ctx.emit('handleChange', selectValue.value)
    }
    // 验证数据
    const checkData = () => {
      if (selectValue.value === '') {
        message.error('请选择道具')
        return false
      }
      if (checkRadio.value === 0) {
        message.error('请选择类型')
        return false
      }
      return true
    }
    const handelOK = () => {
      if (!checkData()) return false
      ctx.emit('handelOK', selectValue.value, checkRadio.value, textareaValue.value)
    }
    const filterOption = (input, option) => {
      console.log(input, option)
      return (
        option.label.match(input)
      )
    }
    watch(
      () => props.visible,
      newValue => {
        if (newValue) {
          selectValue.value = ''
          checkRadio.value = 0
          textareaValue.value = ''
        }
      }
    )
    return {
      selectValue,
      handleCancel,
      handleChange,
      handelOK,
      ListType,
      checkRadio,
      textareaValue,
      filterOption
    }
  }
})
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  margin-bottom: 20px;
  label {
    flex: 0 0 60px;
  }
}
.box-con {
  min-height: 400px;
}
</style>
