<template>
  <a-modal
    width="60%"
    :visible="visible"
    title="修改备注"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handelOK"
  >
    <a-row>
      <a-col :span="2">备注：</a-col>
      <a-col :span="22">
        <a-textarea
          v-model:value="content"
          placeholder="请输入备注"
          :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  props: {
    list: {},
    visible: Boolean
  },
  setup(props, ctx) {
    const content = ref('')

    const handleCancel = () => {
      ctx.emit('handleCancel', false)
    }

    const handelOK = () => {
      ctx.emit('handleOK', content.value)
    }
    watch(
      () => props.list,
      newValue => {
        if (newValue) {
          content.value = newValue.tips
        }
      }
    )
    return {
      content,
      handleCancel,
      handelOK
    }
  }
})
</script>

<style>
</style>
